import { PRODUCT_AREA } from '@leagueplatform/analytics';
import { ANALYTICS_SCREEN_NAMES } from 'common/constants';
import {
  MasonryButtonSecondary,
  MasonryButtonSecondaryProps,
} from 'components/masonry-button-secondary/masonry-button-secondary.component';

export const PwpPrintButton = (props: MasonryButtonSecondaryProps) => (
  <MasonryButtonSecondary
    // Need to pass in analytics manually into widget, masonry currently does not support this
    analyticsProps={{
      screen_name: ANALYTICS_SCREEN_NAMES.PWP,
      product_area: PRODUCT_AREA.JOURNEY,
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);
