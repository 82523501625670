import { PRODUCT_AREA } from '@leagueplatform/analytics';
import { ANALYTICS_SCREEN_NAMES } from 'common/constants';
import {
  StatusBannerDataV2,
  StatusBannerMasonryWidgetV2,
} from 'components/status-banner-masonry-widget/status-banner-masonry-widget.component';

export const PWPDisclaimerWidget = (props: StatusBannerDataV2) => (
  <StatusBannerMasonryWidgetV2
    // Need to pass in analytics manually into widget, masonry currently does not support this
    analyticsProps={{
      screen_name: ANALYTICS_SCREEN_NAMES.PWP,
      product_area: PRODUCT_AREA.JOURNEY,
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);
