import React, { useContext } from 'react';
import {
  Box,
  GDSStyleObject,
  StackItem,
  StackLayout,
  TextAction,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { useHistory } from '@leagueplatform/routing';
import { Image } from '@leagueplatform/genesis-commons';
import shuffleIcon from 'assets/icons/shuffle.svg';
import { useIntl } from '@leagueplatform/locales';
import { QUERY_PARAM_KEYS } from 'common/sso-constants';
import { MODAL_PARAMS } from 'hooks/use-query-modal-content.hook';
import { RegionSelectionContext } from 'components/header-nav/region-selection-context';

type RegionSelectionBannerProps = {
  css?: GDSStyleObject;
  containerCss?: GDSStyleObject;
};

export const RegionSelectionBanner = ({
  css,
  containerCss,
}: RegionSelectionBannerProps) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { selectedRegion, regionOptions } = useContext(RegionSelectionContext);
  const regionOptionsLength = regionOptions?.length || 0;
  const planName = selectedRegion?.planName || '';

  // Dynamically calculate the left offset of the banner relative to the viewport
  // and then adjust accordingly so its positioned against the left edge.
  const [left, setLeft] = React.useState(0);
  const [scrollbarWidth, setScrollbarWidth] = React.useState(0);
  const containerRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    const calculateScrollbarWidth = () => {
      const scrollWidth =
        window.innerWidth - document.documentElement.clientWidth;
      setScrollbarWidth(scrollWidth);
    };
    const handleResize = () => {
      const rect = containerRef.current?.getBoundingClientRect();
      const offsetLeft = rect?.left ?? 0;
      setLeft(offsetLeft);
    };
    handleResize();
    calculateScrollbarWidth();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setLeft]);

  if (regionOptionsLength < 2 || !planName) {
    return null;
  }

  const navigateToModal = () => {
    history.push({
      search: `?${QUERY_PARAM_KEYS.modal}=${MODAL_PARAMS.SWITCH_REGION}`,
    });
  };

  return (
    <Box ref={containerRef} css={{ position: 'relative', ...containerCss }}>
      <StackLayout
        orientation="horizontal"
        horizontalAlignment="spaceBetween"
        verticalAlignment="center"
        css={{
          left: left ? -left : undefined,
          backgroundColor: '$interactiveActionPrimary',
          borderRadius: '0',
          position: 'absolute',
          width: `calc(100vw - ${scrollbarWidth}px)`,
          height: '40px',
          maxWidth: `calc(100vw - ${scrollbarWidth}px)`,
          ...css,
        }}
      >
        <UtilityText
          size={{ '@initial': 'base', '@mobile': 'sm' }}
          css={{
            color: '$onSurfaceTextReversed',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginRight: 'auto',
            maxWidth: '80%',
            '@mobile': { size: 'xs', paddingLeft: '$threeQuarters' },
            '@mobileLandscape': { paddingLeft: '$threeQuarters' },
            '@tablet': { paddingLeft: '$threeQuarters' },
            '@laptop': { paddingLeft: '$oneAndHalf' },
            '@desktop': { paddingLeft: '58px' },
          }}
        >
          {planName}
        </UtilityText>
        <TextAction
          as="button"
          size="medium"
          css={{
            color: '$onSurfaceTextReversed',
            minWidth: '105px',
            paddingLeft: '2px',
            marginLeft: '16px',
            '&:focus': { border: '1px solid $onSurfaceTextReversed' },
            '@mobile': { size: 'xs', paddingRight: '5%' },
            '@mobileLandscape': { paddingRight: '4%' },
            '@tablet': { paddingRight: '4%' },
            '@laptop': { paddingRight: '6%' },
            '@desktop': { paddingRight: '8%' },
            '@media (max-width: 520px)': {
              paddingRight: '5.5%',
            },
            '@media (max-width: 480px)': {
              paddingRight: '6%',
            },
            '@media (max-width: 400px)': {
              paddingRight: '7%',
            },
            '@media (max-width: 360px)': {
              paddingRight: '8%',
            },
            '@media (max-width: 340px)': {
              paddingRight: '8.5%',
            },
          }}
          onClick={navigateToModal}
        >
          {formatMessage({ id: 'SWITCH_VIEW' })}
          <StackItem css={{ marginLeft: '$half' }}>
            <Image src={shuffleIcon} width="$one" alt="" />
          </StackItem>
        </TextAction>
      </StackLayout>
    </Box>
  );
};
