import React, { useEffect } from 'react';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { LoadingSpinner } from 'components/spinner/loading-spinner.component';
import { useLocation } from '@leagueplatform/routing';

// AuthStartLogin is used to trigger authentication using a specific Auth0 connection.
// It is not supposed to be directly navigated to by members.
// This route is used by third-party systems as the entry point to the application
// whenever they wish to automatically trigger an authentication request.
//
// Its main use case is for Identity Provider Initiated SSO attempts, where the request
// comes directly from Highmark. One example of this is impersonation, which begins
// in Highmark's Identity Provider for Support Agents (OAM), which then relays the
// authentication payload to the member-facing applications.
//
// This is needed for security reasons, as IdP initiated SSOs might be vulnerable to CSRF.
// To avoid that risk, we restart the authentication process when it hits our application.
export const AuthThirdPartyStartLogin = () => {
  const location = useLocation();

  useEffect(() => {
    const connection =
      new URLSearchParams(location.search).get('connection') || undefined;

    const options = {
      connection,
    };

    StandaloneAuth.client.loginWithRedirect(options);
  }, [location]);

  return <LoadingSpinner />;
};
