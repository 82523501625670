import {
  SCREEN_NAMES,
  trackAnalyticsEvent,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import {
  GDSHeadingLevel,
  HeadingText,
  StackLayout,
  TextAction,
} from '@leagueplatform/genesis-core';
import {
  ContainerWidgetItem,
  useGetWidgetData,
} from '@leagueplatform/masonry-api';
import { Link as RouterLink } from '@leagueplatform/routing';
import React from 'react';
import { MASONRY } from 'common/constants';
import { SkeletonBox } from '@leagueplatform/web-common-components';

export interface SectionHeaderLinkData {
  heading: string;
  heading_level: GDSHeadingLevel;
  action_button_label: string;
  action_button_url: string;
}

const trackViewAllClick = (subDetail: string) => {
  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
    product_area: SCREEN_NAMES.HOME_SCREEN,
    screen_name: SCREEN_NAMES.HOME_SCREEN,
    detail: 'section header with link',
    sub_detail: subDetail,
  });
};

export const SectionHeaderLink = ({
  widget,
}: {
  widget: ContainerWidgetItem;
}) => {
  const {
    data: widgetData,
    isLoading,
    error,
  } = useGetWidgetData<SectionHeaderLinkData>(widget, MASONRY.API_VERSION);

  if (isLoading) {
    return <SkeletonBox height="48px" />;
  }

  if (!widgetData || !widgetData.attributes || error) {
    return null;
  }

  return (
    <StackLayout
      orientation="horizontal"
      horizontalAlignment="spaceBetween"
      verticalAlignment="center"
      css={{
        width: '100%',
        marginTop: '$half',
      }}
    >
      <HeadingText
        size="xl"
        level={widgetData.attributes.heading_level}
        css={{ textWrap: 'balance' }}
      >
        {widgetData.attributes.heading}
      </HeadingText>
      <TextAction
        as={RouterLink}
        to={widgetData.attributes.action_button_url}
        css={{ textDecoration: 'none', textWrap: 'nowrap' }}
        onClick={() =>
          trackViewAllClick(widgetData.attributes.heading.toLowerCase())
        }
      >
        {widgetData.attributes.action_button_label}
      </TextAction>
    </StackLayout>
  );
};
