/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  GDSParagraphTextProps,
  HeadingText,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import {
  defaultHTMLTagOptions,
  HTMLTagOptions,
} from '@leagueplatform/web-common';

export const htmlToReactOptionsOverride: HTMLTagOptions = {
  h2: {
    component: (props) => <HeadingText {...props} />,
    props: {
      css: {
        marginBottom: '$one',
      },
    },
  },
  h3: {
    component: (props) => <HeadingText {...props} />,
    props: {
      css: {
        marginBottom: '$half',
      },
    },
  },
  h4: {
    component: (props) => <HeadingText {...props} />,
    props: {
      css: {
        marginBottom: '$half',
      },
    },
  },
};

export const RichTextRenderer = (props: GDSParagraphTextProps<'p'>) => (
  <ParagraphText
    htmlStringOptions={{
      ...defaultHTMLTagOptions,
      ...htmlToReactOptionsOverride,
    }}
    {...props}
  />
);
