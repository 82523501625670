import React from 'react';
import { HeadingText, StackLayout } from '@leagueplatform/genesis-core';

import { RichTextRenderer } from 'components/rich-text-renderer/rich-text-renderer.component';

/** Masonry Custom Card component to display rich text */
export const RichTextSection = ({
  heading,
  body,
}: {
  heading?: string;
  body: string;
}) => (
  <StackLayout spacing="$one">
    {heading && (
      <HeadingText level="2" size="xl">
        {heading}
      </HeadingText>
    )}
    <StackLayout>
      <RichTextRenderer>{body}</RichTextRenderer>
    </StackLayout>
  </StackLayout>
);
